import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { Col, Row, Container } from "reactstrap"
import { NormalField } from "reactstrap-form-fields"
import {
  makeStyles,
  useTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles"
import themep from "./theme"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Paper from "@material-ui/core/Paper"
import Slider from "@material-ui/core/Slider"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// @@ Services Desired - choose one
// ## Optimize Current Website, Redesign Current Website, Completely New Design
// ## FaClone, FaEdit, FaLayerGroup
// --
// @@ Primary Goals - multiple
// ## Increase brand awareness, Increase sales, Get people to contact us, Get people to sign up
//  FaBullhorn, FaHandshake, FaRegComments, FaUserPlus
import {
  FaClone,
  FaEdit,
  FaLayerGroup,
  FaBullhorn,
  FaHandshake,
  FaRegComments,
  FaUserPlus,
  FaServicestack,
  FaBullseye,
  FaFolderOpen,
} from "react-icons/fa"

const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}))

const Box = styled.div`
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  border-color: #f4f5f7;
  transition-duration: 0.3s;
  text-align: center;
  height: 12rem;
  border-top: 2px solid ${(props) => props.theme.primary};
  &:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  p {
    color: black;
  }
  @media only screen and (max-width: 600px) {
    height: 10.5rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
const Hr = styled.hr`
  width: 100%;
  border-radius: 0.5rem;
  margin-left: 2em;
  border-width: 0.1rem;
  background: ${(props) => props.theme.primary};
`
const PrettoSlider = withStyles({
  root: {
    color: `${primary}`,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const Estimate = () => {
  const classes = useStyles()
  const theme = useTheme()
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))

  const [dialogOpen, setDialogOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedFirst, setSelectedFirst] = useState("")
  const [selectedSecond, setSelectedSecond] = useState([])
  const [pages, setPages] = useState(3)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [comments, setComments] = useState("")

  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const valuetext = (value) => {
    switch (value) {
      case 12:
        return `${value} +`
      default:
        return value
    }
  }

  function getSteps() {
    return [
      "Select desired service",
      "Select website goals",
      "Select number of pages",
    ]
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Fragment>
            <Row>
              <Col md={4} lg={3}>
                <Box
                  style={{
                    border:
                      selectedFirst === "New Website"
                        ? `6px solid ${primary} `
                        : undefined,
                  }}
                  title="New Website"
                  onClick={(e) => {
                    selectedFirst !== "New Website"
                      ? setSelectedFirst(e.currentTarget.title)
                      : setSelectedFirst("")
                  }}
                >
                  <Row>
                    <FaLayerGroup
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6
                      className="text-center"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      New Website
                    </h6>
                  </Row>
                </Box>
              </Col>
              <Col md={4} lg={3}>
                <Box
                  style={{
                    border:
                      selectedFirst === "Current Website Redesign"
                        ? `6px solid ${primary} `
                        : undefined,
                  }}
                  title="Current Website Redesign"
                  onClick={(e) => {
                    selectedFirst !== "Current Website Redesign"
                      ? setSelectedFirst(e.currentTarget.title)
                      : setSelectedFirst("")
                  }}
                >
                  <Row>
                    <FaEdit
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6 style={{ marginLeft: "auto", marginRight: "auto" }}>
                      Current Website Redesign
                    </h6>
                  </Row>
                </Box>
              </Col>
              <Col md={4} lg={3}>
                <Box
                  style={{
                    border:
                      selectedFirst === "Current Website Optimization"
                        ? `6px solid ${primary} `
                        : undefined,
                  }}
                  title="Current Website Optimization"
                  onClick={(e) => {
                    selectedFirst !== "Current Website Optimization"
                      ? setSelectedFirst(e.currentTarget.title)
                      : setSelectedFirst("")
                  }}
                >
                  <Row>
                    <FaClone
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6 style={{ marginLeft: "auto", marginRight: "auto" }}>
                      Current Website Optimization
                    </h6>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Fragment>
        )
      // return `For each ad campaign that you create, you can control how much
      //         you're willing to spend on clicks and conversions, which networks
      //         and geographical locations you want your ads to show on, and more.`
      case 1:
        return (
          // <Box onClick={() => setSelectedSecond([{ id: 1 }, { id: 2 }])}></Box>
          <Fragment>
            <Row>
              <Col md={3}>
                <Box
                  title="Increase Brand Awareness"
                  style={{
                    border: selectedSecond.includes("Increase Brand Awareness")
                      ? `6px solid ${primary}`
                      : undefined,
                  }}
                  onClick={(e) => {
                    selectedSecond.includes("Increase Brand Awareness")
                      ? setSelectedSecond(
                          selectedSecond.filter(
                            (option) => option !== "Increase Brand Awareness"
                          )
                        )
                      : setSelectedSecond([
                          ...selectedSecond,
                          "Increase Brand Awareness",
                        ])
                  }}
                >
                  <Row>
                    <FaBullhorn
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6 style={{ marginLeft: "auto", marginRight: "auto" }}>
                      Increase Brand Awareness
                    </h6>
                  </Row>
                </Box>
              </Col>

              <Col md={3}>
                <Box
                  title="Increase Sales"
                  style={{
                    border: selectedSecond.includes("Increase Sales")
                      ? `6px solid ${primary}`
                      : undefined,
                  }}
                  onClick={(e) => {
                    selectedSecond.includes("Increase Sales")
                      ? setSelectedSecond(
                          selectedSecond.filter(
                            (option) => option !== "Increase Sales"
                          )
                        )
                      : setSelectedSecond([...selectedSecond, "Increase Sales"])
                  }}
                >
                  <Row>
                    <FaHandshake
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6 style={{ marginLeft: "auto", marginRight: "auto" }}>
                      Increase Sales
                    </h6>
                  </Row>
                </Box>
              </Col>

              <Col md={3}>
                <Box
                  title="Get People to Contact You"
                  style={{
                    border: selectedSecond.includes("Get People to Contact You")
                      ? `6px solid ${primary}`
                      : undefined,
                  }}
                  onClick={(e) => {
                    selectedSecond.includes("Get People to Contact You")
                      ? setSelectedSecond(
                          selectedSecond.filter(
                            (option) => option !== "Get People to Contact You"
                          )
                        )
                      : setSelectedSecond([
                          ...selectedSecond,
                          "Get People to Contact You",
                        ])
                  }}
                >
                  <Row>
                    <FaRegComments
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6 style={{ marginLeft: "auto", marginRight: "auto" }}>
                      Get People to Contact You
                    </h6>
                  </Row>
                </Box>
              </Col>

              <Col md={3}>
                <Box
                  title="Get People to Sign Up"
                  style={{
                    border: selectedSecond.includes("Get People to Sign Up")
                      ? `6px solid ${primary}`
                      : undefined,
                  }}
                  onClick={(e) => {
                    selectedSecond.includes("Get People to Sign Up")
                      ? setSelectedSecond(
                          selectedSecond.filter(
                            (option) => option !== "Get People to Sign Up"
                          )
                        )
                      : setSelectedSecond([
                          ...selectedSecond,
                          "Get People to Sign Up",
                        ])
                  }}
                >
                  <Row>
                    <FaUserPlus
                      size={60}
                      style={{
                        color: `${primary}`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Row>
                  <Row>
                    <h6 style={{ marginLeft: "auto", marginRight: "auto" }}>
                      Get People to Sign Up
                    </h6>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Fragment>
        )
      case 2:
        return (
          <Fragment>
            <div style={{ width: 200 }}>
              <PrettoSlider
                className="mt-5 ml-1"
                key={valuetext}
                defaultValue={3}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                step={1}
                min={1}
                max={12}
                valueLabelDisplay="on"
                onChange={(e, val) => setPages(val)}
              />
            </div>
            <p className="text-muted" style={{ fontSize: ".85rem" }}>
              ex: home, about us, contact us, services, directories,
              testimonials
            </p>
          </Fragment>
        )
      default:
        return "Unknown step"
    }
  }

  const figurePrices = () => {
    let price = 175

    let serviceX = 1
    let scopeX = 1
    // let pagesX = 1 + pages / 10
    let pagesX = pages * 100

    if (selectedFirst === "New Website") {
      serviceX += 0.5
    }

    if (selectedSecond === "Increase Sales") {
      scopeX += 0.25
    }
    if (selectedSecond.includes("Increase Brand Awareness")) {
      scopeX += 0.05
    }
    if (selectedSecond.includes("Get People to Contact You")) {
      scopeX += 0.05
    }
    if (selectedSecond.includes("Get People to Sign Up")) {
      scopeX += 0.25
    }
    return price * serviceX * scopeX + pagesX
  }

  return (
    <ThemeProvider theme={themep}>
      <Hr />
      <Container className="py-1">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {/* <p>{getStepContent(index)}</p> */}
                {getStepContent(index)}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      // onClick={handleBack}
                      onClick={() => (activeStep === 0 ? null : handleBack())}
                      style={{
                        backgroundColor: activeStep === 0 ? "#ccc" : undefined,
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleNext}
                      onClick={() =>
                        activeStep === 0
                          ? selectedFirst.length !== 0
                            ? handleNext()
                            : null
                          : activeStep === 1
                          ? selectedSecond.length !== 0
                            ? handleNext()
                            : null
                          : activeStep === steps.length - 1
                          ? setDialogOpen(true)
                          : null
                      }
                      style={{
                        backgroundColor:
                          activeStep === 0
                            ? selectedFirst.length === 0
                              ? "#ccc"
                              : undefined
                            : activeStep === 1
                            ? selectedSecond.length === 0
                              ? "#ccc"
                              : undefined
                            : undefined,
                      }}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <p>All steps completed - you&apos;re finished</p>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </Paper>
        )}
      </Container>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="lg"
        fullScreen={matchesSM}
        style={{ zIndex: 1302 }}
      >
        <Grid container justify="center">
          <Grid item style={{ marginTop: "1em", marginBottom: "1em" }}>
            <h1 className="text-center" style={{ color: `${primary}` }}>
              Estimate
            </h1>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            justify="space-around"
            direction={matchesSM ? "column" : "row"}
            alignItems={matchesSM ? "center" : undefined}
          >
            <Grid
              item
              container
              direction="column"
              md={7}
              style={{ maxWidth: "20em" }}
            >
              <form name="estimate" method="POST" netlify data-netlify="true">
                <input type="hidden" name="form-name" value="estimate" />
                <NormalField
                  name="Full Name"
                  onChange={(e) => setName(e.currentTarget.value)}
                />
                <NormalField
                  name="Email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
                <NormalField
                  name="Phone Number"
                  onChange={(e) => setPhone(e.currentTarget.value)}
                />
                <NormalField
                  name="Comments"
                  onChange={(e) => setComments(e.currentTarget.value)}
                  type="textarea"
                />
                <NormalField
                  className="d-none"
                  name="Selections"
                  type="textarea"
                  value={
                    selectedFirst
                      ? selectedSecond
                        ? `${selectedFirst}, ${selectedSecond}, ${pages} pages, $${figurePrices().toFixed(
                            0
                          )}`
                        : ""
                      : ""
                  }
                  readOnly
                />
                <Grid item>
                  <Typography
                    variant="body1"
                    align={matchesSM ? "center" : undefined}
                    paragraph
                    style={{ lineHeight: 1.25 }}
                  >
                    We can build this web application for an estimated{" "}
                    <span
                      style={{
                        color: `${primary}`,
                        fontWeight: 700,
                        fontSize: "1.55em",
                      }}
                    >
                      ${figurePrices().toFixed(0)}
                    </span>
                  </Typography>
                  <Typography
                    align={matchesSM ? "center" : undefined}
                    paragraph
                  >
                    Fill out your contact info and the details of your request.
                    We'll get back to you with further details and a final
                    price.
                  </Typography>
                </Grid>
                <div className="text-center">
                  {" "}
                  {/* <Button
                    type="submit"
                    style={{
                      marginTop: ".15em",
                      marginBottom: "1em",
                      backgroundColor:
                        name.length === 0 ||
                        email.length === 0 ||
                        phone.length === 0 ||
                        comments.length === 0
                          ? "#ccc"
                          : undefined,
                    }}
                    onClick={() =>
                      name.length === 0 ||
                      email.length === 0 ||
                      phone.length === 0 ||
                      comments.length === 0
                        ? null
                        : undefined
                    }
                  >
                    Submit
                  </Button> */}
                  {name.length === 0 ||
                  email.length === 0 ||
                  (phone.length === 0) | (comments.length === 0) ? (
                    <Button
                      type="submit"
                      style={{
                        marginTop: ".15em",
                        marginBottom: "1em",
                        backgroundColor:
                          name.length === 0 ||
                          email.length === 0 ||
                          phone.length === 0 ||
                          comments.length === 0
                            ? "#ccc"
                            : undefined,
                      }}
                      onClick={() =>
                        name.length === 0 ||
                        email.length === 0 ||
                        phone.length === 0 ||
                        comments.length === 0
                          ? null
                          : undefined
                      }
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      as="button"
                      style={{
                        marginTop: ".15em",
                        marginBottom: "1em",
                        backgroundColor:
                          name.length === 0 ||
                          email.length === 0 ||
                          phone.length === 0 ||
                          comments.length === 0
                            ? "#ccc"
                            : undefined,
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems={matchesSM ? "center" : undefined}
              md={5}
              style={{ maxWidth: "30em" }}
            >
              <Hidden smDown>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    style={{
                      marginTop: selectedSecond.length > 2 ? "5em" : "7em",
                    }}
                  >
                    <Grid item container alignItems="center">
                      <Grid item xs={2}>
                        <FaServicestack
                          size={30}
                          style={{ color: `${primary}` }}
                          alt="service"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <h5>{selectedFirst}</h5>
                      </Grid>
                    </Grid>
                  </Grid>
                  {selectedSecond.map((selection, index) => (
                    <Grid
                      container
                      direction="column"
                      style={{ marginTop: "1em" }}
                      key={index}
                    >
                      <Grid item container alignItems="center">
                        <Grid item xs={2}>
                          <FaBullseye
                            size={30}
                            style={{ color: `${primary}` }}
                            alt="bullseye target"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <h5>{selection}</h5>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid
                    container
                    direction="column"
                    style={{ marginTop: "1em" }}
                  >
                    <Grid item container alignItems="center">
                      <Grid item xs={2}>
                        <FaFolderOpen
                          size={30}
                          style={{ color: `${primary}` }}
                          alt="folder"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <h5>
                          {pages} {pages !== 1 ? "pages" : "page"}
                        </h5>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              {/* <Grid item>
                <Button variant="contained">Place Request</Button>
              </Grid> */}
              <Hidden mdUp>
                <Grid item style={{ marginBottom: matchesSM ? "5em" : 0 }}>
                  <Button
                    style={{ fontWeight: 300 }}
                    color="primary"
                    onClick={() => setDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}

/////////////////////////
const Button = styled.div`
  -webkit-appearance: unset !important;
  border-radius: 0.35rem;
  margin-top: 1rem;
  margin-right: 1rem;
  background: ${(props) => props.theme.primary};
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
  color: white;
  display: inline-block;
  padding: 0.6125rem 1rem;
  transition-duration: 0.25s;
  font-size: 1rem;
  border: none;
  &:hover {
    cursor: pointer;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1),
      0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1);
  }
`

export default Estimate
