import React from "react"

import { useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import ServiceItem from "./serviceItem"
import solo from "../assets/images/solo.svg"
import partner from "../assets/images/partner.svg"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const primary = "#26a27b"

export default function Solutions() {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ marginLeft: matchesXS ? 0 : "2em" }}
      >
        <Grid item>
          <h4 className="text-center" style={{ color: `${primary}` }}>
            Snappy websites, any way you want them
          </h4>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ marginLeft: matchesXS ? 0 : "2em" }}
      >
        <Grid item xs={12} sm={10} md={7}>
          <p className="text-center mt-1 pb-2 text-muted">
            No matter what current phase your idea, project, or business is in,
            Snappy can help.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={2}
        className="mt-2"
        style={{ marginLeft: matchesXS ? 0 : matchesSM ? "1.5em" : 0 }}
      >
        <ServiceItem
          icon={solo}
          title="Go Solo"
          body="Redesign your website and continue hosting it the same way you always have"
        />
        <ServiceItem
          icon={partner}
          title="Partner with Snappy"
          body="Avoid the headaches and let Snappy get you where you need to go, including hosting."
        />
      </Grid>
    </Grid>
  )
}
