import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Estimate from "../components/estimate"
import Solutions from "../components/solutions"
// import ConvinceToAction from "../components/convincetoaction"
// import arches from "../assets/images/arches.png"
import Seo from "../components/seo"
import EstimateForm from "../components/estimateform"
import QuoteForm from '../components/PricingContact'

import { Col, Row, Container } from "reactstrap"
import Hidden from "@material-ui/core/Hidden"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import DynamicBanner from '../components/DynamicBanner'

const primary = "#26a27b"

const Background = styled.div`
  background: linear-gradient(60deg, #147452 25%, #26a27b 92.13%, #3bb18c 100%);
  color: white;
  padding: 23rem 0;
  margin-left: 50%;
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
  text-align: center;
  @media only screen and (max-width: 1280px) {
    padding: 14rem 0;
    height: 100%;
  }
`

const GreyBackground = styled.div`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 19%,
    rgba(248, 249, 250, 1) 100%
  );
  color: white;
  padding: 12rem 0;
  height: 90%;
  display: flex;
  width: 100%;
  position: absolute;
  clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
  @media only screen and (max-height: 652px) {
    padding: 6rem 0;
    height: 100%;
  }
`
const BackgroundHelper = styled.div`
  height: 7rem;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(248, 249, 250, 1) 100%
  );
`

const LightBox = styled.span`
  position: absolute;
  mix-blend-mode: normal;
  height: 60%;
  width: 18%;
  bottom: 8.5rem;
  transform: rotate(-180deg);
  opacity: 0.41;
  z-index: 10;
  right: 33%;
  clip-path: polygon(60% 0px, 100% 0px, 45% 100%, 0% 100%);
  background: linear-gradient(
    180.28deg,
    rgb(102, 218, 181) 0.19%,
    rgba(102, 218, 181, 0) 79.32%
  );
`
const LightBox2 = styled.span`
  position: absolute;
  mix-blend-mode: normal;
  height: 60%;
  width: 15%;
  bottom: 8.5rem;
  transform: rotate(-180deg);
  opacity: 0.41;
  z-index: 10;
  right: 12%;
  clip-path: polygon(40% 0px, 100% 0px, 40% 100%, 0% 100%);
  background: linear-gradient(
    180.28deg,
    rgb(102, 218, 181) 0.19%,
    rgba(102, 218, 181, 0) 79.32%
  );
`
const LightBox3 = styled.span`
  position: absolute;
  mix-blend-mode: normal;
  height: 30%;
  width: 13%;
  bottom: 12.5rem;
  transform: rotate(-190deg);
  opacity: 0.3;
  z-index: 10;
  right: 10%;
  clip-path: polygon(40% 0px, 100% 0px, 40% 100%, 0% 100%);
  background: linear-gradient(
    17.5deg,
    rgb(102, 218, 181) 3.65%,
    rgba(102, 218, 181, 0) 93.23%
  );
`
const LightBox4 = styled.span`
  position: absolute;
  clip-path: polygon(40% 0px, 100% 0px, 63% 100%, 0% 100%);
  mix-blend-mode: normal;
  opacity: 0.51;
  height: 27%;
  width: 12%;
  z-index: 10;
  background: linear-gradient(
    180.27deg,
    rgb(102, 218, 181) 0.24%,
    rgba(102, 218, 181, 0) 79.38%
  );
  left: 65.5%;
`
// const MBackground = styled.div`
//   /* background: linear-gradient(60deg, #147452 25%, #26a27b 92.13%, #3bb18c 100%); */
//   background: linear-gradient(50deg, #147452 10%, #26a27b 92.13%, #3bb18c 100%);
//   color: white;
//   height: 50vh;
//   clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

//   text-align: center;
// `

let Pricing = () => {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Layout>
      <Seo
        title="Pricing"
        description="You won't believe how affordable it is to create your website with Snappy. Get an instant quote, completely free"
      />
      <Hidden mdDown>
        <GreyBackground>
          <Col md={5} style={{ marginLeft: "50px" }}>
            <Row className="justify-content-left text-left mr-5">
              <h1 style={{ color: `${primary}` }}>
                Ready to build a better web?
              </h1>
            </Row>
            <Row className="justify-content-left text-left mr-5 pt-3" md={7}>
              <h5 style={{ color: "black", lineHeight: 1.25 }}>
                The modern web has astounding capabilities, and we're excited to
                help bring your website up to speed.
              </h5>
            </Row>
            <Row className="justify-content-left text-left mr-5 pt-3" md={7}>
              <h6 style={{ color: "black", fontWeight: 500, lineHeight: 1.5 }}>
                You won't believe how affordable it is to upgrade your website
                by partnering with{" "}
                <span style={{ color: `${primary}`, fontWeight: 700 }}>
                  Snappy
                </span>
                . Use our free estimate tool below to get an instant quote
              </h6>
            </Row>
          </Col>
        </GreyBackground>
        <LightBox />
        <LightBox2 />
        <LightBox3 />
        <LightBox4 />
        <Background />
        <BackgroundHelper />
      </Hidden>
      <Hidden lgUp>
        <DynamicBanner header="Pricing" align="center" subheader="Forget everything you know about website pricing. Let us wow you." />
        {/* <MBackground>
          <h1
            style={{
              paddingTop: matchesXS
                ? "40%"
                : matchesSM
                ? "20%"
                : matchesMD
                ? "13%"
                : undefined,
            }}
          >
            Pricing
          </h1>
        </MBackground>
        <Container
          style={{
            backgroundColor: "#f8f9fa",
            marginTop: "-5em",
            paddingTop: "10em",
            paddingBottom: "5em",
            minWidth: "100%",
          }}
        >
          <Row className="justify-content-center text-center">
            <h3 style={{ color: `${primary}` }}>
              Ready to build a better web?
            </h3>
          </Row>
          <Row className="justify-content-center text-center mx-3 py-3">
            <p>
              The modern web has astounding capabilities. You won't believe how
              affordable it is to get your website up to speed.
            </p>
          </Row>
        </Container> */}
      </Hidden>
      <Container style={{ marginTop: matchesMD ? "7em" : "2em" }}>
        <Solutions />
      </Container>
      <Container
        style={{
          marginTop: "7em",
          marginBottom: '6rem',
        }}
      >
        <Row style={{ marginLeft: "2em", marginRight: "3em" }}>
          <h1 style={{ color: `${primary}` }}>
            Get a Free Web Design Estimate
          </h1>
        </Row>
        <Row
          style={{
            marginLeft: matchesXS ? "2em" : "2.2em",
            marginRight: matchesXS ? 0 : "3em",
          }}
        >
          <h6 className="text-muted" style={{ color: `${primary}` }}>
            just answer a few simple questions
          </h6>
        </Row>
        {/* <Hr /> */}
        <Estimate />
      </Container>
      {/* <Hidden lgUp>
        <Col
          style={{ paddingTop: "10em", paddingBottom: "10em", width: "100%" }}
        >
          <ConvinceToAction bgimage={arches} />
        </Col>
      </Hidden>
      <Hidden mdDown>
        <Col
          style={{ paddingTop: "10em", paddingBottom: "0em", width: "100%" }}
        >
          <ConvinceToAction bgimage={arches} />
        </Col>
      </Hidden> */}
      <QuoteForm />
      <EstimateForm />
    </Layout>
  )
}

export default Pricing
