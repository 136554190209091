import { createMuiTheme } from "@material-ui/core/styles"

const primary = "#26a27b"

export default createMuiTheme({
  palette: {
    common: {
      green: primary,
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: primary,
        },
        "&$completed": {
          color: primary,
        },
      },
    },
    MuiSlider: {
      root: {
        color: primary,
      },
    },
  },
})
