import React from "react"
import { NormalField } from "reactstrap-form-fields"
import Button from "./btn"

let EstimateForm = () => (
  <form name="estimate" method="POST" netlify data-netlify="true" hidden>
    <NormalField name="Full Name" />
    <NormalField name="Email" />
    <NormalField name="Phone Number" />
    <NormalField name="Comments" type="textarea" />
    <NormalField name="Selections" type="textarea" />
    <Button as="button" type="submit">
      Submit
    </Button>
  </form>
)

export default EstimateForm
